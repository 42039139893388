@import './variables.scss';

html {
  background-color: $bg-blue;
  color: $white;
  font-family: 'Paytone One', sans-serif;
  font-size: 62.5%;
  height: 100vh;
  letter-spacing: $xxs;
}

body {
  background-image: url('../img/river-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 4fr 8.6rem;
}

header {
  background-color: $bg-blue;
  font-size: 10rem;
  letter-spacing: $s;
  text-align: center;
}

main {
  position: relative;
}

h2 {
  filter: drop-shadow($xs $xs $xs $bg-blue);
}

h4 {
  margin-bottom: $m;
}

footer {
  background-color: $bg-blue;
  font-size: $xl;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > h4 {
    margin-bottom: 0;
  }
}

.hide {
  display: none;
}