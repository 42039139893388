*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

@font-face {
  font-family: Paytone One;
  font-weight: 400;
  font-style: normal;
  src: url("PaytoneOne-Regular.67901163.ttf") format("truetype");
}

html {
  color: #fff;
  letter-spacing: .2rem;
  background-color: #103149;
  height: 100vh;
  font-family: Paytone One, sans-serif;
  font-size: 62.5%;
}

body {
  background-image: url("river-bg.4001b29a.png");
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-rows: 1fr 4fr 8.6rem;
  grid-template-columns: 1fr;
  height: 100%;
  display: grid;
}

header {
  letter-spacing: .8rem;
  text-align: center;
  background-color: #103149;
  font-size: 10rem;
}

main {
  position: relative;
}

h2 {
  filter: drop-shadow(.4rem .4rem .4rem #103149);
}

h4 {
  margin-bottom: 1.6rem;
}

footer {
  text-align: center;
  background-color: #103149;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.2rem;
  display: flex;
}

footer > h4 {
  margin-bottom: 0;
}

.hide {
  display: none;
}

#mask {
  background-color: #103149;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-mask-image: linear-gradient(#000, #0000, #103149);
  mask-image: linear-gradient(#000, #0000, #103149);
}

section {
  text-align: center;
  z-index: 1;
  font-size: 4rem;
  position: relative;
}

section > img {
  cursor: pointer;
  border-radius: .2rem;
  width: 20rem;
  margin: 0 auto;
  box-shadow: 0 .2rem .1rem -.1rem #0003, 0 .1rem .1rem #00000024, 0 .1rem .3rem #0000001f;
}

#releases {
  padding-top: 3.2rem;
}

#covers {
  direction: row;
  flex-wrap: wrap;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
}

#covers > img {
  padding-top: 1.6rem;
}

article {
  width: 80%;
  max-width: 85rem;
  margin: 2.4rem auto 0;
}
/*# sourceMappingURL=index.a76d446a.css.map */
