#mask {
  background-color: $bg-blue;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  mask-image: linear-gradient(
    to bottom,
    rgb(0 0 0 / 100%),
    transparent,
    $bg-blue
  );
}

section {
  font-size: 4rem;
  position: relative;
  text-align: center;
  z-index: 1;

  & > img {
    border-radius: $xxs;
    box-shadow: $shadow;
    cursor: pointer;
    margin: 0 auto;
    width: 20rem;
  }
}

#releases {
  padding-top: $xl;
}

#covers {
  display: flex;
  direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 80rem;

  & > img {
    padding-top: $m;
  }
}

article {
  margin: $l auto 0 auto;
  width: 80%;
  max-width: 85rem;
}
